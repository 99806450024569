.c-Campaign {
  $this: &;
  $categoryBg: #{$this}__categoryBg;
  $imgHolder: #{$this}__imgHolder;
  $img: #{$this}__img;
  $moreIcon: #{$this}__moreIcon;

  display: block;
  position: relative;
  z-index: 1;
  box-shadow: 0 0 rsize(10) rgba(#128ffc, 0.2);

  background-color: $COLOR_WHITE;
  border-bottom: 2px solid $COLOR_BLUE;

  @include lg {
    box-shadow: 0 0 20px rgba(#128ffc, 0.2);
    border-bottom-width: 3px;
  }

  &--event {
    #{$categoryBg} {
      fill: $COLOR_ORANGE;
    }
    #{$imgHolder} {
      border-color: $COLOR_ORANGE;
    }
  }

  &--important {
    #{$categoryBg} {
      fill: $COLOR_RED;
    }
    #{$imgHolder} {
      border-color: $COLOR_RED;
    }
  }

  &--update {
    #{$categoryBg} {
      fill: #4ca2a3;
    }
    #{$imgHolder} {
      border-color: #4ca2a3;
    }
  }

  &--campaign {
    #{$categoryBg} {
      fill: #9ec449;
    }
    #{$imgHolder} {
      border-color: #9ec449;
    }
  }

  &__category {
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 1;
    width: rsize(150);
    height: rsize(20);
    text-align: center;

    @include lg {
      width: 254px;
      height: 30px;
    }
  }

  &__categoryLabel {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
    transform: translateY(-35%);
    color: $COLOR_WHITE;
    font-size: rsize(14);
    line-height: 1;
    font-weight: 700;

    @include lg {
      font-size: 1.8rem;
      transform: translateY(-50%);
    }
  }

  &__categoryBg {
    width: rsize(150);
    height: rsize(20);
    position: absolute;
    left: 0;
    bottom: rsize(-2);
    z-index: -1;
    fill: $COLOR_ORANGE;

    @include lg {
      width: 254px;
      height: 30px;
      bottom: 0;
    }
  }

  &__imgHolder {
    border-width: 2px 0;
    border-style: solid;
    overflow: hidden;
    border-color: $COLOR_ORANGE;

    @include lg {
      border-width: 3px 0;
    }
  }

  &__body {
    padding: rsize(25) rsize(20);
    // border-bottom: 2px solid $COLOR_BLUE;

    @include lg {
      padding: 35px 20px;
      // border-bottom-width: 3px;
    }
  }

  &__title {
    font-size: rsize(14);
    font-weight: 500;
    // margin-bottom: rsize(20);

    @include lg {
      font-size: 1.4rem;
      margin-bottom: 55px;
    }
  }

  &__date {
    display: block;
    color: $COLOR_GRAY;
    font-size: rsize(12);
    margin-top: rsize(20);

    @include lg {
      font-size: 1.2rem;
      margin-top: 0;

      position: absolute;
      bottom: 35px;
      left: 20px;
      z-index: 1;
    }
  }

  &__more {
    position: absolute;
    right: rsize(60);
    bottom: rsize(28);
    z-index: 1;
    color: $COLOR_BLUE;
    font-size: rsize(12);
    font-weight: 700;

    @include lg {
      font-size: 1.2rem;
      right: 53px;
      bottom: 35px;
    }
  }

  &__moreIcon {
    width: rsize(30);
    height: rsize(12);
    position: absolute;
    top: 50%;
    left: 100%;
    z-index: 1;
    transform: translate(rsize(10), -50%);
    fill: $COLOR_BLUE;

    @include lg {
      width: 24px;
      height: 10px;
      left: 125%;
      transform: translate(0, -42%);
    }
  }

  .is-pc & {
    #{$moreIcon} {
      transition: transform 0.3s;
    }

    #{$img} {
      transition: transform 0.3s;
    }

    &:hover {
      #{$img} {
        transform: scale(1.1);
      }

      #{$moreIcon} {
        transform: translate3d(rsize(13), -50%, 0);

        @include lg {
          transform: translate3d(3px, -42%, 0);
        }
      }
    }
  }
}
