.c-Entrance {
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  // right: 0;
  // bottom: 0;
  left: 0;
  z-index: -3;
  overflow: hidden;

  [data-pagename="article"] &,
  [data-pagename="story"] &,
  [data-pagename="how_to_play"] & {
    display: none;

    @include lg {
      display: block;
    }
  }

  &__imgHolder {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    // opacity: 0.5;
  }

  &__img {
    width: 140vw;
    max-width: none;
    // position: absolute;
    // top: 0;
    // left: 0;
    // z-index: 99;
    // transform: translate(-50%, -50%) scale(0.5);
    // opacity: 0.5;

    @include lg {
      width: 1000px;
    }
  }

  &__particleHolder {
    position: absolute;
    // top: 50vh;
    // left: 50vw;
    top: 0;
    left: 0;
    z-index: 2;
    // perspective: 1000px;
  }

  // &__particle {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   z-index: 1;
  //   transform: translate(-50%, -50%);
  //   width: 100vw;
  //   max-width: none;

  //   &:nth-child(1) {
  //     width: rsize(180);
  //   }
  //   &:nth-child(2) {
  //     width: rsize(150);
  //   }
  //   &:nth-child(3) {
  //     width: rsize(140);
  //   }
  //   &:nth-child(4) {
  //     width: rsize(450);
  //   }
  //   &:nth-child(5) {
  //     width: rsize(370);
  //   }
  // }

  &__particle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    // transform: translate3d(-50vw, -50vh, 1px);
    // transform: translateZ(1px);
    transform: translateY(100px);
    width: 100vw;
    height: 1000vh;
    opacity: 0.15;
    max-width: none;
    // transform-origin: 50vw 50vh;

    &:nth-child(1) {
      width: 100vw;
      // height: 100vh;
      background-image: url(#{$IMAGE_DIR}particle_01.png);
      background-size: contain;

      @include lg {
        background-size: 1000px;
      }
    }
    &:nth-child(2) {
      width: 100vw;
      // height: 100vh;
      background-image: url(#{$IMAGE_DIR}particle_02.png);
      background-size: contain;

      @include lg {
        background-size: 1000px;
      }
    }
    &:nth-child(3) {
      width: 100vw;
      // height: 100vh;
      background-image: url(#{$IMAGE_DIR}particle_03.png);
      background-size: contain;

      @include lg {
        background-size: 1000px;
      }
    }
    &:nth-child(4) {
      width: 100vw;
      // height: 100vh;
      background-image: url(#{$IMAGE_DIR}particle_04.png);
      background-size: contain;

      @include lg {
        background-size: 1000px;
      }
    }
    &:nth-child(5) {
      width: 100vw;
      // height: 100vh;
      background-image: url(#{$IMAGE_DIR}particle_05.png);
      background-size: contain;

      @include lg {
        background-size: 1000px;
      }
    }
  }
}
