.p-ContactForm {
  padding: 0 rsize(20);
  margin-top: rsize(30);

  @include lg {
    max-width: 780px;
    padding: 0;
    margin-top: 70px;
    margin-right: auto;
    margin-left: auto;
  }

  &__pageTitle {
    display: none;

    @include lg {
      display: block;
      height: 90px;
      margin-top: -70px;
      margin-bottom: 20px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__title {
    text-align: center;
    font-size: rsize(28);
    font-weight: 700;
    line-height: rsize(39);

    @include lg {
      font-size: 4.4rem;
      line-height: 5.8rem;
      margin-top: 20px;
    }
  }
}
