.red {
  color: #ff2626;
}

.blue {
  color: #0093ff;
}

.green {
  color: #00ba5d;
}

.yellow {
  color: #e6cb26;
}
