.c-Modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(#000, 0.7);
  justify-content: center;
  align-items: center;

  display: none;
  opacity: 0;
  visibility: hidden;

  &__inner {
    width: 90%;
  }

  &__close {
    width: rsize(50);
    height: rsize(50);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    background-color: $COLOR_WHITE;
    cursor: pointer;

    @include lg {
      width: 50px;
      height: 50px;
      top: 10px;
      right: 10px;
    }

    &::before,
    &::after {
      content: "";
      width: rsize(30);
      height: rsize(2);
      top: rsize(24);
      left: rsize(10);
      position: absolute;
      z-index: 1;
      background-color: $COLOR_BLUE;

      @include lg {
        width: 30px;
        height: 2px;
        top: 24px;
        left: 10px;
      }
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &__video {
    position: relative;
    width: 100%;
    padding-top: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      right: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
}
