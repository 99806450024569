// -------------------------------------------------------------------
//  functions.scss
// -------------------------------------------------------------------
$BASE_SIZE_SM: 375;
$BASE_SIZE_MD: 768;
$BASE_SIZE_LG: 1024;

@function rsize($num: 100, $bp: sm) {
  @if $bp == lg {
    @return ($num / $BASE_SIZE_LG * 100vw);
  } @else if $bp == md {
    @return ($num / $BASE_SIZE_MD * 100vw);
  } @else {
    @return ($num / $BASE_SIZE_SM * 100vw);
  }
}
