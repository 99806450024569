.c-Gate {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
  pointer-events: none;

  &--story {
    // margin-top: -300px;
    margin-top: rsize(-300);
    z-index: 2;

    @include lg {
      // margin-top: -400px;
      margin-top: -40vh;
    }
  }

  &__inner {
    margin-top: rsize(-180);

    @include lg {
      margin-top: 0;
    }
  }

  &__button {
    width: rsize(240);
    margin-top: rsize(40);
    pointer-events: all;

    @include lg {
      width: 280px;
      margin-top: 30px;
      margin-right: auto;
      margin-left: auto;
    }

    // display: block;
    // background: #ffffff 0% 0% no-repeat padding-box;
    // box-shadow: 0px 0px rsize(5) unquote("#128ffc99");
    // border: rsize(2) solid #4090f7;
    // border-radius: rsize(15) rsize(6);
    // color: #4090f7;
    // text-align: center;
    // padding: 0.5em 1em;
    // font-family: $FONT_M_PLUS_1p;
    // font-size: rsize(16);
    // font-weight: 800;
    // pointer-events: all;
  }

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
  }
}
