.c-Mv {
  position: relative;
  z-index: 1;
  // height: 100vh;
  height: rsize(490);

  @include lg {
    // height: 570px;
    height: 90vh;
  }

  &__logo {
    position: relative;
    z-index: 2;
    padding-top: rsize(8);

    @include lg {
      padding-top: 0;
    }
  }

  &__logoImg {
    display: block;
    width: rsize(318);
    margin-right: auto;
    margin-left: auto;

    @include lg {
      width: 292px;
    }
  }

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;

    @include lg {
      top: 20px;
    }
  }
}
