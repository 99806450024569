.c-Form {
  &__body {
    margin-top: rsize(5);

    @include lg {
      margin-top: 10px;
    }
  }

  &__label {
    font-size: rsize(14);
    font-weight: 700;

    @include lg {
      font-size: 1.6rem;
    }
  }

  &__required {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: rsize(35);
    font-size: rsize(12);
    color: $COLOR_WHITE;
    background-color: #f76959;
    border-radius: rsize(4);
    margin-left: rsize(10);

    @include lg {
      width: 34px;
      margin-left: 10px;
      font-size: 1.1rem;
      border-radius: 4px;
    }
  }

  &__text {
    width: 100%;
    height: rsize(50);
    padding: 0 1em;
    border: 1px solid #707070;
    border-radius: rsize(10);

    @include lg {
      height: 50px;
      border-radius: 10px;
    }

    &:focus {
      outline: none;
    }
  }

  &__textarea {
    width: 100%;
    padding: 0.5em 1em;
    border: 1px solid #707070;
    border-radius: rsize(10);

    @include lg {
      border-radius: 10px;
    }

    &:focus {
      outline: none;
    }
  }

  &__button {
    width: rsize(240);
    margin-top: rsize(35);
    margin-right: auto;
    margin-left: auto;

    @include lg {
      width: 280px;
      margin-top: 40px;
    }
  }
}
