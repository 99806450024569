.p-404 {
  &__title {
    margin-top: rsize(20);

    @include lg {
      margin-top: 100px;
    }
  }

  &__titleImg {
    display: block;
    margin-right: auto;
    margin-left: auto;

    width: rsize(240);
    height: rsize(145);

    @include lg {
      width: 280px;
      height: 169px;
    }
  }

  &__text {
    text-align: center;
    font-size: rsize(14);
    margin-top: rsize(20);

    @include lg {
      font-size: 1.2rem;
      margin-top: 35px;
    }
  }

  &__button {
    margin-right: auto;
    margin-left: auto;

    width: rsize(240);
    margin-top: rsize(35);

    @include lg {
      width: 280px;
      margin-top: 35px;
      margin-bottom: 238px;
    }
  }
}
