.p-Campaign {
  &__list {
    @include lg {
      width: 960px;
      display: flex;
      flex-wrap: wrap;
      margin-top: 85px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__item {
    &:nth-child(1) {
      margin-top: rsize(50);

      @include lg {
        margin-top: 10px;
      }
    }
    &:nth-child(n + 2) {
      margin-top: rsize(40);

      @include lg {
        margin-top: 10px;
      }
    }

    @include lg {
      width: 460px;
      margin: 60px 10px;
    }

    @include lg {
      position: relative;
      z-index: 1;
      background-color: $COLOR_WHITE;
      border-bottom: 3px solid $COLOR_BLUE;
      box-shadow: 0 0 20px rgba(#128ffc, 0.2);

      .c-Campaign {
        position: static;
        border-bottom: none;
        box-shadow: none;
      }
    }
  }

  &__pagination {
    margin-top: rsize(20);

    @include lg {
      width: 320px;
      margin-top: -20px;
      margin-right: auto;
      margin-left: auto;
    }
  }
}
