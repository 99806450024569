.c-FaqItemList {
  border-bottom: 1px solid $COLOR_OFF_WHITE;

  @include lg {
    border: none;
  }

  @include lg {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  &__item {
    border-top: 1px solid $COLOR_OFF_WHITE;

    @include lg {
      width: 415px;
      border-top: none;
      border-bottom: 1px solid $COLOR_OFF_WHITE;
      margin: 0 15px;

      &:nth-child(-n + 2) {
        border-top: 1px solid $COLOR_OFF_WHITE;
      }
    }
  }

  &__link {
    display: block;
    position: relative;
    z-index: 1;
    color: $COLOR_BLUE;
    font-size: rsize(14);
    padding: rsize(20) rsize(5) rsize(20) rsize(35);

    @include lg {
      font-size: 1.4rem;
      padding: 20px 0 20px 35px;
    }

    &::before {
      content: "Q";
      display: flex;
      justify-content: center;
      align-items: center;
      width: rsize(25);
      height: rsize(25);
      position: absolute;
      top: rsize(20);
      left: 0;
      z-index: 1;
      color: $COLOR_WHITE;
      font-family: $FONT_M_PLUS_1p;
      font-size: rsize(15);
      font-weight: 800;
      line-height: 1;
      background-color: $COLOR_BLUE;
      border-radius: rsize(10) rsize(5);

      @include lg {
        width: 25px;
        height: 25px;
        top: 20px;
        font-size: 1.5rem;
        border-radius: 10px 5px;
      }
    }
  }
}
