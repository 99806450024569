.c-Step {
  position: relative;
  z-index: 1;

  &--1 {
    width: rsize(503);
    height: rsize(292);
    left: rsize(-64);
    margin-top: rsize(25);

    @include lg {
      width: 715px;
      height: 415px;
      left: 0;
      margin-top: 20px;
    }
  }

  &--2 {
    width: rsize(627);
    height: rsize(319);
    left: rsize(-175);
    margin-top: rsize(-92);

    @include lg {
      width: 891px;
      height: 453px;
      left: auto;
      right: 0;
      margin-top: -225px;
      margin-left: auto;
    }
  }

  &__item {
    position: absolute;
    top: 0;
    left: 0;
  }
}