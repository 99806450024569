.p-Faq {
  &__pageTitle {
    display: none;

    @include lg {
      display: block;
      height: 90px;
      margin-top: -70px;
      margin-bottom: 20px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__body {
    padding: 0 rsize(20);

    @include lg {
      width: 780px;
      padding: 0;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__title {
    text-align: center;
    font-size: rsize(28);
    font-weight: 700;
    line-height: rsize(39);

    @include lg {
      font-size: 4.4rem;
      line-height: 5.8rem;
      margin-top: 20px;
    }
  }

  &__list {
    margin-top: rsize(20);

    @include lg {
      margin-top: 40px;
    }
  }

  &__item {
    margin: rsize(20) 0;

    @include lg {
      margin: 20px 0;
    }
  }

  &__category {
    padding: 0 rsize(20) rsize(60);
    margin-top: rsize(60);
    background-color: $COLOR_WHITE;
    border-width: 3px 0;
    border-style: solid;
    border-color: $COLOR_BLUE;
    box-shadow: 0 0 rsize(10) rgba(#128ffc, 0.2);

    @include lg {
      padding: 0 40px 100px;
      margin-top: 80px;
      margin-bottom: 40px;
      box-shadow: 0 0 20px rgba(#128ffc, 0.2);
    }
  }
}
