@charset "UTF-8";

@tailwind base;
@tailwind components;
@tailwind utilities;

// @import "../vendor/bootstrap-alt/functions";
// @import "../vendor/bootstrap-alt/variables";
// @import "../vendor/bootstrap-alt/mixins";
// @import "../vendor/bootstrap-alt/grid";
// @import "../vendor/bootstrap-alt/utilities";
// @import "../vendor/bootstrap-alt/print";

@import "../vendor/swiper";

@import "../variables";
@import "../mixins";
@import "../functions";
@import "../mediaquery";
// @import "../sanitize";
@import "../structure";
@import "../iconfont";
@import "../easing";
@import "../utils";
@import "../color";

@import "../components/app";
@import "../components/c-header";
@import "../components/c-footer";
@import "../components/c-menu";
@import "../components/c-toggle-menu";
@import "../components/container";
@import "../components/c-title";
@import "../components/c-button";
@import "../components/c-page";
@import "../components/c-mv";
@import "../components/c-gate";
@import "../components/c-campaign";
@import "../components/c-character";
@import "../components/c-news-list";
@import "../components/c-tag";
@import "../components/c-date";
@import "../components/c-carousel";
@import "../components/c-pagination";
@import "../components/c-template";
@import "../components/c-step";
@import "../components/c-video";
@import "../components/c-faq";
@import "../components/c-faq-category-list";
@import "../components/c-faq-item-list";
@import "../components/c-faq-list";
@import "../components/c-common";
@import "../components/c-form";
@import "../components/c-entrance";
@import "../components/c-back-button";
@import "../components/c-modal";
@import "../components/c-loading";
@import "../components/c-bg";
@import "../components/c-landscape";

@import "../pages/p-top";
@import "../pages/p-character";
@import "../pages/p-campaign";
@import "../pages/p-campaign-detail";
@import "../pages/p-news";
@import "../pages/p-news-detail";
@import "../pages/p-how-to-play";
@import "../pages/p-faq";
@import "../pages/p-contact";
@import "../pages/p-contact-form";
@import "../pages/p-story";
@import "../pages/p-terms";
@import "../pages/p-beginners";
@import "../pages/p-404";
