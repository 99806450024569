.c-Page {
  position: relative;
  z-index: 1;

  &[data-pagename="story"],
  &[data-pagename="how_to_play"],
  &[data-pagename="character"],
  &[data-pagename="news"],
  &[data-pagename="campaign"],
  &[data-pagename="common"] {
    padding-top: rsize(60);

    @include lg {
      padding-top: 140px;
    }
  }

  &[data-pagename="how_to_play"] {
    position: relative;
    z-index: 1;

    // &::after {
    //   content: "";
    //   width: 100%;
    //   height: 100%;
    //   position: fixed;
    //   top: 0;
    //   left: 0;
    //   z-index: -1;
    //   background-image: url(#{$IMAGE_DIR}mask.png);
    //   // background-size: 1px 3px;
    //   background-size: 2560px 1440px;
    //   opacity: 0.5;
    // }
  }

  &[data-pagename="story"] {
    position: relative;
    z-index: 1;

    // &::before {
    //   content: "";
    //   width: 100%;
    //   height: 110%;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   z-index: -1;
    //   background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 40%);
    // }

    // &::after {
    //   content: "";
    //   width: 100%;
    //   height: 100%;
    //   position: fixed;
    //   top: 0;
    //   left: 0;
    //   z-index: -1;
    //   background-image: url(#{$IMAGE_DIR}mask.png);
    //   // background-size: 1px 3px;
    //   background-size: 2560px 1440px;
    //   opacity: 0.7;
    // }
  }
}
