.c-Footer {
  position: relative;
  z-index: 1;
  height: rsize(355);
  background-image: url(#{$IMAGE_DIR}footer_sm.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 1px;
  margin-bottom: rsize(50);

  @include lg {
    height: 266px;
    background-image: url(#{$IMAGE_DIR}footer_lg.png);
    margin-bottom: 0;
  }

  [data-pagename="top"] & {
    margin-top: rsize(150);

    @include lg {
      margin-top: 160px;
    }
  }

  [data-pagename="common"] &,
  [data-pagename="article"] &,
  [data-pagename="story"] &,
  [data-pagename="how_to_play"] &,
  [data-pagename="news"] &,
  [data-pagename="campaign"] &,
  [data-pagename="character"] & {
    margin-top: rsize(115);

    @include lg {
      margin-top: 160px;
    }
  }

  &__goToTop {
    display: flex;
    justify-content: center;
    align-items: center;
    width: rsize(140);
    height: rsize(50);
    position: absolute;
    top: rsize(18);
    left: rsize(118);
    color: $COLOR_WHITE;
    font-family: $FONT_M_PLUS_1p;
    font-size: rsize(19);
    font-weight: 800;

    @include lg {
      width: 98px;
      height: 59px;
      top: 16px;
      left: 50%;
      font-size: 1.8rem;
      transform: translateX(-50%);
    }
  }

  &__goToTopImg {
    width: rsize(23);
    position: relative;
    z-index: 1;
    bottom: rsize(2);

    @include lg {
      width: 23px;
      bottom: auto;
      left: 2px;
    }
  }

  &__contact {
    width: rsize(240);
    margin-top: rsize(107);
    margin-right: auto;
    margin-left: auto;

    @include lg {
      width: 280px;
      position: absolute;
      top: 112px;
      left: calc(50% + 220px);
      margin-top: 0;
    }
  }

  &__menu {
    margin-top: rsize(35);

    @include lg {
      width: 660px;
      position: relative;
      left: calc(50% - 500px);
      z-index: 1;
      margin-top: 126px;
    }
  }

  &__menuList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__menuItem {
    margin: rsize(5) 0;

    @include lg {
      margin: 0;
    }
  }

  &__menuLink {
    color: $COLOR_WHITE;
    font-size: rsize(13);
    text-decoration: underline;

    @include lg {
      font-size: 1.2rem;
    }

    .is-pc & {
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__copyright {
    color: $COLOR_WHITE;
    font-size: rsize(10);
    opacity: 0.6;
    margin-top: rsize(30);

    @include lg {
      text-align: center;
      font-size: 1.1rem;
      margin-top: 45px;
    }
  }
}
