.p-News {
  padding: 0 rsize(20);

  @include lg {
    max-width: 940px;
    padding: 0;
    margin-right: auto;
    margin-left: auto;
  }

  &__list {
    margin-top: rsize(35);

    @include lg {
      margin-top: 70px;
    }
  }

  &__pagination {
    margin-top: rsize(20);

    @include lg {
      width: 320px;
      margin-top: 40px;
      margin-right: auto;
      margin-left: auto;
    }
  }
}
