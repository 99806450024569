// Width
// -------------------------------------------------------------------
$BREAK_POINT_MD: 737px;
$BREAK_POINT_LG: 1024px;
$BREAK_POINT_XL: 1280px;

// Font
// -------------------------------------------------------------------
$BASE_FONT_FAMILY: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif;
$BASE_FONT_SIZE_LG: 1.4rem;
$BASE_FONT_SIZE_MD: 1.4rem;
$BASE_FONT_SIZE: 1.2rem;
$BASE_LINE_HEIGHT: 1.8;

// Web Font
// -------------------------------------------------------------------
$FONT_NOTO_SANS: "Noto Sans JP", sans-serif;
$FONT_M_PLUS_1p: "M PLUS 1p", sans-serif;

// Color
// -------------------------------------------------------------------
$COLOR_WHITE: #fff;
$COLOR_OFF_WHITE: #ddd;
$COLOR_LIGHT_GRAY: #ccc;
$COLOR_BLACK: #000;
$COLOR_INK: #333;
$COLOR_DARK_GRAY: #666;
$COLOR_GRAY: #999;
$COLOR_BLUE: #4090f7;
$COLOR_RED: #d8562e;
$COLOR_ORANGE: #e8ab40;

// Image Path
// -------------------------------------------------------------------
$IMAGE_DIR: "../img/";
