.c-Pagination {
  $this: &;
  $icon: #{$this}__icon;

  display: flex;
  align-items: center;
  margin-right: rsize(-5);
  margin-left: rsize(-5);

  &__ctrl {
    width: rsize(50);
    height: 0;
    position: relative;
    z-index: 1;
    border: rsize(2) solid $COLOR_BLUE;
    border-radius: rsize(6) rsize(15);
    padding-top: rsize(46);
    margin-right: rsize(5);
    margin-left: rsize(5);
    box-shadow: 0 0 rsize(10) rgba(#128ffc, 0.2);
    background-color: $COLOR_WHITE;
    overflow: hidden;
    cursor: pointer;

    @include lg {
      width: 50px;
      padding-top: 46px;
      border-width: 2px;
      border-radius: 8px 18px;
      margin-right: 5px;
      margin-left: 5px;
      box-shadow: 0 0 20px rgba(#128ffc, 0.2);
    }

    .is-pc & {
      transition: background-color 0.3s;

      #{$icon} {
        transition: fill 0.3s;
      }

      &:hover {
        background-color: $COLOR_BLUE;

        #{$icon} {
          fill: $COLOR_WHITE;
        }
      }
    }

    &--next,
    &--last {
      border-radius: rsize(15) rsize(6);

      @include lg {
        border-radius: 18px 8px;
      }
    }
  }

  &__icon {
    width: rsize(17);
    height: rsize(17);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    fill: $COLOR_BLUE;

    @include lg {
      width: 18px;
      height: 18px;
    }
  }

  &__num {
    flex-grow: 1;
    text-align: center;
    color: $COLOR_BLUE;
    font-family: $FONT_M_PLUS_1p;
    font-size: rsize(16);

    @include lg {
      font-size: 1.6rem;
    }
  }
}
