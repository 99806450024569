.p-TopCampaignNews {
  margin-top: rsize(130);

  @include lg {
    margin-top: 100px;
    width: 1000px;
    display: flex;
    margin-right: auto;
    margin-left: auto;
  }

  &__col {
    @include lg {
      width: 460px;

      &:last-child {
        margin-left: 80px;
      }
    }
  }
}

.p-TopCampaign {
  padding: 0 rsize(20);

  @include lg {
    padding: 0;
  }

  &__body {
    margin-top: rsize(25);
    @include lg {
      margin-top: 35px;
    }
  }

  &__button {
    width: rsize(240);
    position: relative;
    z-index: 2;
    margin-top: rsize(40);
    margin-right: auto;
    margin-left: auto;

    @include lg {
      width: 280px;
      margin-top: 35px;
    }
  }
}

.p-TopNews {
  margin-top: rsize(180);
  padding: 0 rsize(20);

  @include lg {
    padding: 0;
    margin-top: 0;
  }

  &__body {
    position: relative;
    z-index: 1;
    margin-top: rsize(35);

    @include lg {
      margin-top: 35px;
    }
  }

  &__button {
    width: rsize(240);
    position: relative;
    z-index: 2;
    margin-top: rsize(40);
    margin-right: auto;
    margin-left: auto;

    @include lg {
      width: 280px;
      margin-top: 35px;
    }
  }
}

.p-TopCharacter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: rsize(-160);

  @include lg {
    // position: relative;
    // left: 272px;
    // z-index: 1;
    margin-top: -130px;
  }

  &__img {
    width: rsize(650);
    max-width: none;

    @include sm-md {
      position: relative;
      right: rsize(-15);
      z-index: -1;
      margin-top: rsize(-50);
    }

    @include lg {
      // width: 772px;
      max-width: 1000px;
      margin-top: -90px;
      // position: absolute;
      // top: -30px;
      // bottom: 0;
      // right: auto;
      // left: calc(50% - 900px);
      // margin: auto;
    }
  }

  &__button {
    width: rsize(240);
    margin-top: rsize(-55);
    margin-right: auto;
    margin-left: auto;

    @include lg {
      width: 280px;
      margin-top: -90px;
    }
  }
}
