.p-Character {
  $this: &;
  $thumbnailImg: #{$this}__thumbnailImg;

  position: relative;
  z-index: 1;

  @include lg {
    width: 780px;
    margin-right: auto;
    margin-left: auto;
  }

  &__detail {
    height: rsize(380);
    position: relative;
    z-index: 1;
    margin-top: rsize(35);
    font-family: $FONT_M_PLUS_1p;
    overflow: hidden;

    @include lg {
      height: 654px;
      font-size: 1.8rem;
      margin-top: 70px;
    }

    &::before {
      content: "";
      width: rsize(558);
      height: rsize(95);
      position: absolute;
      left: rsize(-91.5);
      bottom: rsize(27);
      z-index: 1;
      background-image: url(#{$IMAGE_DIR}character/shadow.svg);
      background-repeat: no-repeat;
      background-size: contain;

      @include lg {
        width: 779px;
        height: 89px;
        left: calc(50% - 390px);
        bottom: -8px;
      }
    }
  }

  &__nameHolder {
    width: rsize(334);
    height: rsize(87);
    position: absolute;
    bottom: rsize(19);
    right: 0;
    left: 0;
    z-index: 2;
    margin: auto;
    font-weight: 800;
    background-image: url(#{$IMAGE_DIR}character/bg_character_name.svg);
    background-repeat: no-repeat;
    background-size: contain;

    @include lg {
      width: 480px;
      height: 124px;
      bottom: 16px;
    }
  }

  &__popularName {
    width: 100%;
    position: absolute;
    top: rsize(1);
    left: 0;
    z-index: 1;
    text-align: center;
    color: $COLOR_WHITE;
    font-size: rsize(18);

    @include lg {
      top: 2px;
      font-size: 2.4rem;
    }
  }

  &__name {
    width: 100%;
    position: absolute;
    top: rsize(32);
    left: 0;
    z-index: 1;
    text-align: center;
    color: $COLOR_BLUE;
    font-size: rsize(28);

    @include lg {
      top: 44px;
      font-size: 4.2rem;
    }
  }

  &__img {
    width: rsize(365);
    position: absolute;
    top: rsize(45);
    right: 0;
    left: 0;
    z-index: 1;
    margin: auto;

    @include lg {
      width: 700px;
      top: 70px;
    }
  }

  &__id {
    position: absolute;
    top: rsize(-4);
    left: rsize(290);
    z-index: 3;

    @include lg {
      top: -8px;
      right: 45px;
      left: auto;
    }
  }

  &__attr {
    width: rsize(40);
    height: rsize(40);
    position: absolute;
    top: 0;
    left: rsize(20);
    z-index: 3;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: rsize(15) rsize(5);
    background-size: rsize(24) rsize(29);

    @include lg {
      width: 60px;
      height: 60px;
      left: 40px;
      border-radius: 24px 8px;
      background-size: 36px;
    }

    &--red {
      background-color: #fa2525;
      background-image: url(#{$IMAGE_DIR}character/attr_red.svg);
    }

    &--blue {
      background-color: #4090f7;
      background-image: url(#{$IMAGE_DIR}character/attr_blue.svg);
    }

    &--green {
      background-color: #34d32e;
      background-image: url(#{$IMAGE_DIR}character/attr_green.svg);
    }

    &--yellow {
      background-color: #ffe328;
      background-image: url(#{$IMAGE_DIR}character/attr_yellow.svg);
    }

    &::before {
      content: "";
      width: rsize(295);
      height: 1px;
      position: absolute;
      top: rsize(20);
      left: rsize(40);
      z-index: 1;
      background-color: rgba(#232346, 0.2);

      @include lg {
        width: 638px;
        top: 30px;
        left: 60px;
      }
    }
  }

  &__rank {
    display: flex;
    position: absolute;
    top: 0;
    left: rsize(70);
    z-index: 3;

    @include lg {
      left: 115px;
    }

    > svg {
      width: rsize(14);
      height: rsize(14);
      fill: #232346;

      @include lg {
        width: 18px;
        height: 18px;
      }

      &:nth-child(n + 2) {
        margin-left: rsize(3);

        @include lg {
          margin-left: 5px;
        }
      }
    }
  }

  &__tribeType {
    display: flex;
    position: absolute;
    top: rsize(21);
    left: rsize(70);
    z-index: 3;

    @include lg {
      top: 36px;
      left: 115px;
    }
  }

  &__tribe {
    // position: absolute;
    // top: rsize(21);
    // left: rsize(70);
    // z-index: 3;

    // @include lg {
    //   top: 36px;
    //   left: 115px;
    // }
  }

  &__type {
    margin-left: rsize(20);

    @include lg {
      margin-left: 40px;
    }
    // position: absolute;
    // top: rsize(21);
    // left: rsize(150);
    // z-index: 3;

    // @include lg {
    //   top: 36px;
    //   left: 230px;
    // }
  }

  &__cv {
    position: absolute;
    right: rsize(20);
    bottom: rsize(0);
    z-index: 2;
    padding-right: rsize(15);
    padding-left: rsize(15);
    color: $COLOR_BLUE;
    background-color: $COLOR_WHITE;
    border: 2px solid $COLOR_BLUE;
    border-radius: rsize(15) rsize(6);
    overflow: hidden;

    @include lg {
      right: 110px;
      bottom: 0;
      padding-right: 15px;
      padding-left: 15px;
      border-radius: 18px 8px;
    }

    // &::before {
    //   content: "";
    //   width: rsize(27);
    //   height: rsize(25);
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   z-index: 1;
    //   // background-color: $COLOR_BLUE;
    //   background-image: url(#{$IMAGE_DIR}character/sound.svg);
    //   background-repeat: no-repeat;
    //   background-size: rsize(16) rsize(14);
    //   background-position: center;

    //   @include lg {
    //     width: 37px;
    //     height: 32px;
    //     background-size: 21px 19px;
    //   }
    // }

    // &::after {
    //   content: "";
    //   width: rsize(27);
    //   height: rsize(25);
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   z-index: -1;
    //   background-color: $COLOR_BLUE;

    //   @include lg {
    //     width: 37px;
    //     height: 32px;
    //   }
    // }

    // .is-pc & {
    //   &:hover {
    //     &::before {
    //       animation: pulse 0.5s $easeInOutSine alternate infinite;
    //     }
    //   }

    //   @keyframes pulse {
    //     0% {
    //       transform: scale(1);
    //     }
    //     100% {
    //       transform: scale(0.8);
    //     }
    //   }
    // }
  }

  &__awake {
    width: rsize(48);
    height: rsize(37);
    position: absolute;
    top: 0;
    right: rsize(20);
  }

  &__ctrlHolder {
    width: 100%;
    position: absolute;
    top: rsize(170);
    left: 0;
    z-index: 1;

    @include lg {
      top: 340px;
    }
  }

  &__ctrl {
    position: absolute;
    z-index: 1;
    padding: rsize(10);
    cursor: pointer;

    @include lg {
      padding: 10px;
    }

    &:focus {
      outline: none;
    }

    .is-pc & {
      transition: transform 0.3s;
    }

    &--prev {
      left: 0;

      @include lg {
        left: -90px;
      }

      .is-pc & {
        &:hover {
          transform: translate3d(-10px, 0, 0);
        }
      }
    }

    &--next {
      right: 0;

      @include lg {
        right: -90px;
      }

      .is-pc & {
        &:hover {
          transform: translate3d(10px, 0, 0);
        }
      }
    }
  }

  &__ctrlImg {
    width: rsize(20);
    height: rsize(33);

    @include lg {
      width: 28px;
      height: 50px;
    }
  }

  &__thumbnailList {
    display: flex;
    flex-wrap: wrap;
    margin-top: rsize(20);
    margin-left: rsize(18);

    @include lg {
      width: 770px;
      margin-top: 50px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__thumbnailItem {
    width: rsize(64);
    height: rsize(64);
    margin: rsize(2);
    position: relative;
    z-index: 1;
    background-color: $COLOR_BLUE;
    border: 2px solid $COLOR_WHITE;
    border-radius: rsize(15) rsize(5);
    box-shadow: 0 0 rsize(10) rgba(#128ffc, 0.2);
    overflow: hidden;
    cursor: pointer;

    @include lg {
      width: 90px;
      height: 90px;
      border-radius: 18px 8px;
      margin: 10px;
    }

    &.is-active {
      border-color: $COLOR_BLUE;
      border-width: 3px;
      pointer-events: none;

      @include lg {
        border-width: 4px;
      }
    }

    .is-pc & {
      &:hover {
        #{$thumbnailImg} {
          transform: scale(1.2);
        }
      }
    }

    // &::before {
    //   content: "";
    //   width: rsize(64);
    //   height: rsize(64);
    //   position: absolute;
    //   top: rsize(-2);
    //   left: rsize(-2);
    //   z-index: -2;
    //   background-color: $COLOR_WHITE;
    //   border-radius: rsize(15) rsize(5);
    // }
  }

  &__thumbnailImg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: transform 0.3s;
  }
}
