.c-Common {
  font-size: rsize(14);
  font-weight: 400;
  background-color: $COLOR_WHITE;
  border-width: 3px 0;
  border-style: solid;
  border-color: $COLOR_BLUE;
  box-shadow: 0 0 rsize(10) rgba(#128ffc, 0.2);
  padding: rsize(60) rsize(20);

  @include lg {
    padding: 60px 40px;
    box-shadow: 0 0 20px rgba(#128ffc, 0.2);
    font-size: 1.4rem;
  }

  p {
    margin: rsize(10) 0;

    @include lg {
      margin: 15px 0;
    }
  }

  dl {
    &:first-child {
      dt:first-child {
        margin-top: 0;
      }
    }
  }

  dt {
    font-size: rsize(16);
    font-weight: 700;
    margin-top: rsize(40);

    @include lg {
      font-size: 1.6rem;
      margin-top: 40px;
    }
  }

  li {
    padding-left: rsize(37);
    text-indent: rsize(-37);
    margin: rsize(10) 0;

    @include lg {
      padding-left: 2.7em;
      text-indent: -2.7em;
      margin: 10px 0;
    }
  }

  a {
    color: $COLOR_BLUE;
    text-decoration: underline;
  }

  small {
    display: block;
    font-size: rsize(12);
    margin-top: rsize(-5);

    @include lg {
      font-size: 1.2rem;
      margin-top: 5px;
    }
  }

  hr {
    border-top: 1px solid $COLOR_OFF_WHITE;
    margin: rsize(30) 0;

    @include lg {
      border-top-width: 2px;
      margin: 30px 0;
    }
  }

  &__list {
    > li {
      padding-left: 1em;
      text-indent: -1em;
      font-size: rsize(14);
      font-weight: 400;

      @include lg {
        font-size: 1.4rem;
      }
    }
  }
}
