html,
body {
  overflow-x: hidden;
  width: 100%;
}

html {
  font-size: 62.5%;
}

body {
  color: $COLOR_INK;
  font-family: $FONT_NOTO_SANS;
  font-weight: 500;
  line-height: $BASE_LINE_HEIGHT;

  @include sm-only {
    font-size: rsize(14);
  }

  @include md-only {
    font-size: $BASE_FONT_SIZE_MD;
  }

  @include lg {
    font-size: $BASE_FONT_SIZE_LG;
  }
}

p {
  margin: 0;
}

a {
  color: $COLOR_BLACK;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}
