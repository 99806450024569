// Carousel configuration parameters
$viewer-distance: 200px;

.c-Carousel {
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  height: rsize(105);
  background-image: url(#{$IMAGE_DIR}bg_carousel.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: rsize(322) rsize(105);

  @include lg {
    width: 900px;
    height: 160px;
    background-size: contain;
    margin-top: -55px;
    margin-right: auto;
    margin-left: auto;
  }

  &__inner {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;

    perspective: $viewer-distance;

    @include lg {
      perspective: 500px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__img {
    display: block;
    width: rsize(302);
    position: absolute;
    max-width: none;

    @include lg {
      width: 460px;
    }

    .is-pc & {
      &.is-current {
        &:hover {
          transition: transform 0.3s $easeOutBack;
          transform: scale(1.1) !important;
          z-index: 99;
        }
      }
    }

    &[data-state="active"] {
      transform: rotateY(0deg);
    }
    &[data-state="next"] {
      transform: rotateY(20deg);
      transform-origin: left center;
    }
    &[data-state="afternext"] {
      transform: rotateY(50deg);
      transform-origin: left center;
      transition: none;
    }
    &[data-state="last"] {
      transform: rotateY(-20deg);
      transform-origin: right center;
    }
    &[data-state="beforelast"] {
      transform: rotateY(-50deg);
      transform-origin: right center;
    }
    &[data-state="hidden"] {
      transition: none;
      opacity: 0;
    }
  }

  &__ctrlHolder {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: flex;
    justify-content: center;
  }

  &__ctrl {
    width: rsize(25);
    height: rsize(48);
    position: absolute;
    z-index: 1;
    transform: translateY(-50%);
    background-repeat: no-repeat;
    padding-top: rsize(48);
    overflow: hidden;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    @include lg {
      width: 37px;
      height: 69px;
      padding-top: 69px;
    }

    &--prev {
      top: 0;
      right: rsize(153);
      background-image: url(#{$IMAGE_DIR}carousel_prev.svg);

      @include lg {
        right: 232px;
      }
    }

    &--next {
      top: 0;
      left: rsize(153);
      background-image: url(#{$IMAGE_DIR}carousel_next.svg);

      @include lg {
        left: 232px;
      }
    }
  }
}
