.c-FaqList {
  background-color: $COLOR_WHITE;
  border-width: 3px 0;
  border-style: solid;
  border-color: $COLOR_BLUE;
  padding: 0 rsize(20) rsize(60);
  box-shadow: 0 0 rsize(10) rgba(#128ffc, 0.2);

  @include lg {
    padding: 0 40px 100px;
  }

  &__title {
    position: relative;
    z-index: 1;
    font-size: rsize(23);
    font-weight: 700;
    text-align: center;
    margin-top: rsize(60);
    margin-bottom: rsize(35);

    @include lg {
      font-size: 2.6rem;
      margin-top: 90px;
      margin-bottom: 50px;
    }

    &::before {
      content: "";
      width: rsize(53);
      height: rsize(53);
      position: absolute;
      top: rsize(-4);
      right: 0;
      left: 0;
      z-index: -1;
      margin: auto;
      background-image: url(#{$IMAGE_DIR}bg_title_blue.svg);
      background-size: contain;

      @include lg {
        width: 50px;
        height: 50px;
        top: 0;
      }
    }
  }
}
