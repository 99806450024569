.c-Loading {
  display: flex;
  // display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: #fff;
  font-family: $FONT_M_PLUS_1p;

  p {
    display: inline-block;
  }

  &__inner {
  }

  &__balloon {
    width: rsize(277);
    position: relative;
    display: block;
    margin-bottom: rsize(25);
    margin-right: auto;
    margin-left: auto;
    padding: rsize(13) rsize(21);
    font-size: rsize(18);
    background: #fff;
    border: solid 3px $COLOR_BLUE;
    box-sizing: border-box;
    border-radius: rsize(16) rsize(8);

    @include lg {
      width: 285px;
      font-size: 1.8rem;
      border-radius: 16px 8px;
      padding: 15px 25px;
      margin-bottom: 25px;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: -22px;
      left: 50%;
      margin-left: -15px;
      border: 8px solid transparent;
      border-top: 14px solid #fff;
      z-index: 2;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -29px;
      left: 50%;
      margin-left: -17px;
      border: 10px solid transparent;
      border-top: 16px solid #39f;
      z-index: 1;
    }
  }

  &__state {
    text-align: center;
    font-size: rsize(12);
    margin-top: rsize(10);

    @include lg {
      font-size: 1.4rem;
      margin-top: 15px;
    }
  }

  &__progress {
    border: 2px solid #39f;
    width: rsize(125);
    height: rsize(10);
    border-radius: 99px;
    margin-right: auto;
    margin-left: auto;
    margin-top: rsize(3);

    @include lg {
      width: 160px;
      height: 10px;
      margin-top: 3px;
    }
  }

  &__progress-bar {
    background-color: #39f;
    width: 0%;
    height: 100%;
  }

  &__icon {
    display: block;
    width: rsize(72);
    height: rsize(88);
    position: relative;
    right: rsize(5);
    z-index: 1;
    fill: $COLOR_BLUE;
    margin-right: auto;
    margin-left: auto;

    @include lg {
      width: 80px;
      height: 96px;
      right: 5px;
    }
  }
}
