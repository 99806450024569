.p-Contact {
  padding: 0 rsize(20);
  margin-top: rsize(35);

  @include lg {
    max-width: 940px;
    padding: 0;
    margin-top: 70px;
    margin-right: auto;
    margin-left: auto;
  }

  &__other {
    margin-top: rsize(50);
    margin-right: auto;
    margin-left: auto;

    @include lg {
      width: 420px;
      margin-top: 40px;
    }

    .c-Button {
      padding-top: 0.8em;
      padding-bottom: 0.8em;

      @include lg {
        padding-top: 1em;
        padding-bottom: 1em;
      }
    }
  }
}
