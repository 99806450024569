.c-Character {
  position: absolute;
  max-width: none;
  pointer-events: none;

  @include lg {
    display: none;
  }

  &--1 {
    width: rsize(80);
    top: rsize(-72);
    right: rsize(-20);
    z-index: -1;
  }

  &--2 {
    width: rsize(250);
    bottom: rsize(-157);
    left: rsize(-65);
    z-index: 2;
  }

  &--3 {
    width: rsize(182);
    bottom: rsize(-149);
    right: rsize(-20);
    z-index: 2;
  }
}
