.p-Beginners {
  padding: 0 rsize(20);
  margin-top: rsize(30);

  @include lg {
    max-width: 940px;
    padding: 0;
    margin-top: 70px;
    margin-right: auto;
    margin-left: auto;
  }

  &__row {
    margin-top: rsize(40);

    @include lg {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -20px;
    }
  }

  &__col {
    @include lg {
      margin: 20px;
    }

    &:nth-child(1) {
      @include lg {
        width: 320px;
      }
    }

    &:nth-child(2) {
      @include lg {
        width: 500px;
        display: flex;
        align-items: center;
        flex-grow: 1;
        flex-shrink: 1;
      }
    }
  }

  .c-Video__title {
    margin: 0;
  }

  &__back {
    @include lg {
      margin-top: 40px;
    }
  }
}
