.p-Story {
  &__title {
    position: relative;
    z-index: 1;
    text-align: center;
    color: $COLOR_WHITE;
    font-size: rsize(18);
    font-weight: 700;
    margin-top: rsize(35);

    @include lg {
      font-size: 1.8rem;
      margin-top: 60px;
    }

    &::before {
      content: "";
      width: rsize(174);
      height: rsize(30);
      position: absolute;
      top: rsize(2);
      right: 0;
      left: 0;
      z-index: -1;
      margin: auto;
      background-image: url(#{$IMAGE_DIR}bg_title_whatsalice.svg);
      background-size: contain;
      background-repeat: no-repeat;

      @include lg {
        width: 174px;
        height: 30px;
        top: 2px;
      }
    }
  }

  &__filter {
    width: 100%;
    height: 110%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 30%);

    @include lg {
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 40%);
    }
  }
}

.p-StoryCatch {
  width: rsize(340);
  height: rsize(308);
  position: relative;
  z-index: 1;
  margin-top: rsize(20);
  margin-right: auto;
  margin-left: auto;
  overflow: visible;

  @include lg {
    margin-top: 40px;
  }

  @include lg {
    width: 750px;
    height: 640px;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;

    &--character {
      transform-origin: rsize(260) rsize(165);

      @include lg {
        transform-origin: 536px 328px;
      }
    }
  }
}

.p-StorySubCatch {
  margin-top: rsize(20);
  margin-bottom: rsize(140);

  @include lg {
    width: 600px;
    margin-top: 60px;
    margin-bottom: 160px;
    margin-right: auto;
    margin-left: auto;
  }
}

.p-StorySec {
  &__title {
    position: relative;
    z-index: 1;
    text-align: center;
    // color: $COLOR_WHITE;
    font-size: rsize(23);
    font-weight: 700;
    margin-top: rsize(80);
    margin-bottom: rsize(45);

    @include lg {
      font-size: 2.6rem;
      margin-top: 80px;
      margin-bottom: 40px;
    }

    &::before {
      content: "";
      width: rsize(53);
      height: rsize(53);
      position: absolute;
      top: rsize(-4);
      right: 0;
      left: 0;
      z-index: -1;
      margin: auto;
      background-image: url(#{$IMAGE_DIR}bg_title_blue.svg);
      background-size: contain;

      @include lg {
        width: 50px;
        height: 50px;
        top: 0;
      }
    }
  }

  &__textContainer {
    @include lg {
      margin-bottom: 75px;
    }
  }

  &__text {
    text-align: center;
    // color: $COLOR_WHITE;
    font-size: rsize(14);
    margin: rsize(25) 0;

    @include lg {
      font-size: 1.4rem;
      margin: 2em 0;
    }
  }

  &__img {
    margin: rsize(30) 0;

    @include lg {
      width: 460px;
      margin: 40px 0;
      margin-right: auto;
      margin-left: auto;
    }
  }
}
