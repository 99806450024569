.c-Bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  // right: 0;
  // bottom: 0;
  left: 0;
  z-index: -2;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  // height: rsize(667);

  // img {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   z-index: 1;
  //   transform: translate(-50%, -50%);
  //   height: 100%;
  // }
}
