.c-FaqCategoryList {
  $this: &;
  $link: #{$this}__link;

  @include lg {
    display: flex;
    flex-wrap: wrap;
    margin: -12px;
  }

  &--col2 {
    #{$link} {
      @include lg {
        width: 338px;
      }
    }
  }

  &__item {
    margin: rsize(10) 0;
    background-color: $COLOR_WHITE;

    @include lg {
      margin: 12px;
    }
  }

  &__link {
    display: block;
    position: relative;
    z-index: 1;
    color: $COLOR_BLUE;
    font-size: rsize(14);
    text-align: center;
    padding: 0.6em 1em;
    border: 2px solid $COLOR_BLUE;
    border-radius: rsize(6);

    @include lg {
      width: 270px;
      font-size: 1.4rem;
      border-radius: 8px;
      padding-right: 1.5em;
    }

    &::after {
      content: "";
      width: rsize(8);
      height: rsize(12);
      position: absolute;
      top: 50%;
      right: rsize(20);
      z-index: 1;
      transform: translateY(-50%);
      background-image: url(#{$IMAGE_DIR}arrow_right_blue.svg);
      background-repeat: no-repeat;
      background-size: contain;

      @include lg {
        width: 8px;
        height: 12px;
        right: 20px;
      }
    }

    .is-pc & {
      transition: color 0.3s, background-color 0.3s;

      &::after {
        transition: transform 0.3s;
      }

      &:hover {
        color: $COLOR_WHITE;
        background-color: $COLOR_BLUE;

        &::after {
          background-image: url(#{$IMAGE_DIR}arrow_right_wht.svg);
          transform: translate(3px, -50%);
        }
      }
    }
  }
}
