.c-Landscape {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: $COLOR_WHITE;

  @include landscape {
    .is-sp & {
      display: flex;
    }
  }

  &__inner {
    width: 60%;
    text-align: center;
  }

  &__logo {
    height: 40vh;
    margin-right: auto;
    margin-left: auto;
  }

  &__text {
    font-size: 3.7vh;
    margin-top: 5vh;
  }
}
