.c-Tag {
  width: rsize(110);
  text-align: center;
  color: $COLOR_WHITE;
  font-size: rsize(14);
  font-weight: 700;
  line-height: 1.4;
  border-radius: rsize(2);
  background-color: #e8ab40;

  @include lg {
    width: 106px;
    font-size: 1.4rem;
    border-radius: 4px;
  }

  &--important {
    background-color: #d8562e;
  }

  &--maintenance {
    background-color: #97aeb9;
  }

  &--other {
    background-color: #98a8d5;
  }

  &--event {
    background-color: #e8ab40;
  }

  &--failureinfo {
    background-color: #d8562e;
  }

  &--update {
    background-color: #4ca2a3;
  }

  &--campaign {
    background-color: #9ec449;
  }
}
