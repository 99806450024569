.c-Faq {
  $this: &;
  $state: #{$this}__state;

  position: relative;
  z-index: 1;
  background-color: $COLOR_WHITE;
  border: 2px solid $COLOR_BLUE;
  border-radius: rsize(15) rsize(6);
  box-shadow: 0 0 rsize(10) rgba(#128ffc, 0.2);
  box-sizing: content-box;
  overflow: hidden;

  @include lg {
    border-radius: 18px 8px;
    box-shadow: 0 0 20px rgba(#128ffc, 0.2);
  }

  &.is-accordion-open {
    #{$state} {
      transform: rotate(180deg);

      &::after {
        opacity: 0;
      }
    }
  }

  &__head {
    position: relative;
    z-index: 1;
    color: $COLOR_BLUE;
    font-size: rsize(14);
    font-weight: 700;
    padding: rsize(15) rsize(40) rsize(15) rsize(55);
    cursor: pointer;

    @include lg {
      font-size: 1.4rem;
      padding: 18px 20px 18px 80px;
    }

    &::before {
      content: "Q";
      display: flex;
      justify-content: center;
      align-items: center;
      width: rsize(40);
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      color: $COLOR_WHITE;
      font-family: $FONT_M_PLUS_1p;
      font-size: rsize(22);
      font-weight: 800;
      background-color: $COLOR_BLUE;

      @include lg {
        width: 60px;
        font-size: 2.8rem;
      }
    }

    &::after {
      content: "";
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: -2px;
      left: 0;
      z-index: 1;
      background-color: $COLOR_BLUE;
    }
  }

  &__body {
    font-size: rsize(14);
    padding: rsize(25) rsize(20) rsize(40);

    @include lg {
      font-size: 1.4rem;
      padding: 40px;
    }

    p {
      margin: rsize(25) 0;

      @include lg {
        margin-top: 40px;
        margin-bottom: 40px;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    em {
      font-size: rsize(16);
      font-weight: 700;
      font-style: normal;

      @include lg {
        font-size: 1.6rem;
      }
    }

    img {
      margin: rsize(25) auto;

      @include lg {
        margin-top: 40px;
        margin-bottom: 40px;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__state {
    position: absolute;
    top: rsize(27);
    right: rsize(27);
    z-index: 1;
    transition: transform 0.4s $easeOutPower3;

    @include lg {
      top: 30px;
      right: 30px;
    }

    &::before,
    &::after {
      content: "";
      width: rsize(15);
      height: rsize(2);
      position: absolute;
      top: rsize(-1);
      left: rsize(-7.5);
      z-index: 1;

      @include lg {
        width: 20px;
        height: 2px;
        top: -1px;
        left: -10px;
      }

      background-color: $COLOR_BLUE;
    }

    &::after {
      transition: opacity 0.2s $easeOutPower3;
      transform: rotate(90deg);
    }
  }
}
