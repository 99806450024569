.c-Title {
  color: #232346;
  font-family: $FONT_M_PLUS_1p;
  font-weight: 800;
  line-height: 1;
  text-align: center;

  &__img {
    display: block;
    margin-right: auto;
    margin-left: auto;
    height: 85px;

    @include lg {
      height: 100px;
    }
  }

  &__ja {
    display: block;
    font-size: rsize(40);
    text-shadow: 0px 0px 10px #fff;
  }

  &__en {
    display: table;
    font-size: rsize(18);
    margin-top: rsize(15);
    margin-right: auto;
    margin-left: auto;
    position: relative;
    z-index: 1;
    text-shadow: 0px 0px 10px #fffc;

    &::before,
    &::after {
      content: "";
      width: rsize(55);
      height: rsize(10);
      position: absolute;
      top: rsize(6);
      z-index: 1;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &::before {
      right: 100%;
      margin-right: rsize(10);
      background-image: url(#{$IMAGE_DIR}title_decoration_left.svg);
    }

    &::after {
      left: 100%;
      margin-left: rsize(10);
      background-image: url(#{$IMAGE_DIR}title_decoration_right.svg);
    }
  }
}
