.c-Video {
  cursor: pointer;

  &__title {
    height: rsize(30);
    position: relative;
    z-index: 1;
    text-align: center;
    color: $COLOR_WHITE;
    font-family: $FONT_M_PLUS_1p;
    font-size: rsize(18);
    font-weight: 800;

    @include lg {
      height: 30px;
      font-size: 1.8rem;
    }
  }

  &__titleBg {
    width: rsize(256);
    height: rsize(30);
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    fill: $COLOR_BLUE;

    @include lg {
      width: 254px;
      height: 30px;
    }
  }

  &__thumbnail {
    position: relative;
    z-index: 1;
    border: 2px solid $COLOR_BLUE;

    @include lg {
      border-width: 3px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.2);
    }

    &::after {
      content: "";
      width: rsize(95);
      height: rsize(58);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      margin: auto;
      background-image: url(#{$IMAGE_DIR}play.svg);
      background-repeat: no-repeat;
      background-size: contain;

      @include lg {
        width: 86px;
        height: 53px;
      }
    }

    .is-pc & {
      &::after {
        transition: transform 0.3s $easeOutBack;
      }

      &:hover {
        &::after {
          transform: scale(1.2);
        }
      }
    }
  }
}
