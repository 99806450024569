.c-ToggleMenu {
  width: rsize(130);
  height: 100%;
  position: relative;
  z-index: 1;
  cursor: pointer;

  @include lg {
    width: 76px;
    height: 76px;
    margin-left: auto;
    background-color: $COLOR_WHITE;
    box-shadow: 0 0 10px rgba(#128ffc, 0.6);
  }

  &__label {
    position: absolute;
    top: rsize(15);
    left: rsize(60);
    z-index: 1;
    color: $COLOR_BLUE;
    font-family: $FONT_M_PLUS_1p;
    font-size: rsize(19);
    font-weight: 800;
    line-height: 1;

    @include lg {
      font-size: 10px;
      top: 47px;
      left: 23px;
    }
  }

  &__itemHolder {
    position: absolute;
    top: 50%;
    left: rsize(32.5);
    z-index: 1;

    @include lg {
      top: 29px;
      left: 38px;
    }
  }

  &__item {
    width: rsize(25);
    height: rsize(4);
    position: absolute;
    left: rsize(-12.5);
    z-index: 1;
    background-color: $COLOR_BLUE;
    transition: 0.3s;

    @include lg {
      width: 30px;
      height: 4px;
      left: -15px;
    }

    // top
    &:nth-child(1) {
      top: rsize(-10);

      @include lg {
        top: -9px;
      }

      .is-menu-open & {
        top: rsize(-2);
        transform: skewY(30deg);

        @include lg {
          top: 0px;
        }
      }
    }
    // middle
    &:nth-child(2) {
      top: rsize(-2);

      @include lg {
        top: 0;
      }

      .is-menu-open & {
        opacity: 0;
        pointer-events: none;
      }
    }
    // bottom
    &:nth-child(3) {
      top: rsize(6);

      @include lg {
        top: 9px;
      }

      .is-menu-open & {
        top: rsize(-2);
        transform: skewY(-30deg);

        @include lg {
          top: 0;
        }
      }
    }
  }
}
