.p-NewsDetail {
  &__pageTitle {
    display: none;

    @include lg {
      display: block;
      height: 90px;
      margin-top: 70px;
      margin-bottom: 30px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__pageContainer {
    @include lg {
      max-width: 780px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__pageBody {
    @include lg {
      background-color: $COLOR_WHITE;
      border-top: 3px solid $COLOR_BLUE;
      border-bottom: 3px solid $COLOR_BLUE;
      box-shadow: 0px 0px 20px rgba(#128ffc, 0.2);
      margin-bottom: 40px;
    }
  }

  &__body {
    padding: 0 rsize(20);

    @include lg {
      padding: 0 40px;
    }
  }

  &__title {
    font-size: rsize(26);
    font-weight: 700;
    line-height: rsize(40);
    margin-top: rsize(30);

    @include lg {
      font-size: 2.6rem;
      line-height: 40px;
      margin-top: 70px;
    }
  }

  &__data {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    padding-bottom: rsize(40);
    margin-top: rsize(20);

    @include lg {
      margin-top: 20px;
      padding-bottom: 60px;
    }

    &::before {
      content: "";
      width: 100vw;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: rsize(-20);
      z-index: 1;
      background-color: $COLOR_OFF_WHITE;

      @include lg {
        width: calc(100% + 80px);
        left: -40px;
      }
    }
  }

  &__date {
    margin-left: rsize(15);

    @include lg {
      margin-left: 10px;
    }
  }
}
