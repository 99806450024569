.c-NewsList {
  $this: &;
  $link: #{$this}__link;
  $title: #{$this}__title;
  $data: #{$this}__data;
  $date: #{$this}__date;
  $more: #{$this}__more;
  $moreIcon: #{$this}__moreIcon;

  background-color: $COLOR_WHITE;
  border-width: 2px 0;
  border-style: solid;
  border-color: $COLOR_BLUE;
  box-shadow: 0 0 rsize(10) rgba(#128ffc, 0.2);
  padding: 0 rsize(20);

  @include lg {
    box-shadow: 0 0 20px rgba(#128ffc, 0.2);
  }

  @include lg {
    border-width: 3px 0;
    padding: 0 40px;
  }

  &--wide {
    #{$link} {
      @include lg {
        position: relative;
        z-index: 1;
      }
    }

    #{$title} {
      @include lg {
        margin-left: 208px;
        margin-right: 90px;
      }
    }

    #{$data} {
      @include lg {
        width: 100%;
        margin-top: 0;
        position: absolute;
        top: 42px;
        left: 0;
        z-index: -1;
      }
    }

    #{$date} {
      @include lg {
        margin-left: 10px;
      }
    }

    #{$more} {
      @include lg {
        margin-left: auto;
      }
    }
  }

  &__item {
    &:nth-child(n + 2) {
      border-top: 1px solid $COLOR_OFF_WHITE;
    }
  }

  &__link {
    display: block;
    padding: rsize(40) 0;

    @include lg {
      padding: 40px 0;
    }

    .is-pc & {
      #{$moreIcon} {
        transition: transform 0.3s;
      }

      &:hover {
        #{$moreIcon} {
          transform: translate3d(3px, 0, 0);
        }
      }
    }
  }

  &__title {
    font-size: rsize(14);

    @include lg {
      font-size: 1.4rem;
    }
  }

  &__data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: rsize(15);

    @include lg {
      justify-content: start;
      margin-top: 10px;
    }
  }

  &__date {
    color: $COLOR_GRAY;
    font-size: rsize(12);

    @include lg {
      font-size: 1.2rem;
      margin-left: 10px;
    }
  }

  &__more {
    display: flex;
    align-items: center;
    color: $COLOR_BLUE;
    font-size: rsize(12);
    font-weight: 700;

    @include lg {
      font-size: 1.2rem;
      margin-left: auto;
    }
  }

  &__moreIcon {
    display: inline-block;
    width: rsize(30);
    height: rsize(12);
    margin-left: rsize(10);
    fill: $COLOR_BLUE;

    @include lg {
      width: 24px;
      height: 10px;
      margin-left: 10px;
    }
  }
}
