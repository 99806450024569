.c-Menu {
  $this: &;
  $subIcon: #{$this}__subIcon;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: #2a3a4f;
  background-image: url(#{$IMAGE_DIR}bg_menu.png);
  background-size: 1px 4px;
  pointer-events: all;
  transform: translate3d(0, 100%, 0);
  transition: 0.3s $easeInOutSine;

  .is-menu-open & {
    transform: translate3d(0, 0, 0);
  }

  &__logo {
    width: rsize(300);
    height: rsize(67);
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    background-image: url(#{$IMAGE_DIR}bg_menu_logo.png);
    background-repeat: no-repeat;
    background-size: contain;

    @include lg {
      width: 352px;
      height: 100px;
    }
  }

  &__logoImg {
    display: block;
    width: rsize(159);
    height: rsize(24);
    position: absolute;
    top: rsize(13);
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);

    @include lg {
      width: 200px;
      height: 31px;
      top: 13px;
    }
  }

  &__list {
    display: flex;
    width: 100%;
    // height: rsize(420);
    height: 55vh;
    position: absolute;
    // bottom: rsize(115);
    top: 20vh;
    left: 0;
    z-index: 1;
    flex-direction: column;
    justify-content: space-between;
    // align-items: center;
    text-align: center;

    @include lg {
      height: 500px;
      top: auto;
      bottom: 50%;
      transform: translateY(50%);
    }
  }

  &__link {
    color: $COLOR_WHITE;
    font-family: $FONT_M_PLUS_1p;
    font-size: rsize(28);
    font-weight: 800;
    line-height: 1;
    text-shadow: 0 0 rsize(10) #007fff;

    @include lg {
      font-size: 2.8rem;
      text-shadow: 0 0 20px #007fff;
    }

    &--top {
      [data-pagename="top"] & {
        pointer-events: none;
        color: $COLOR_BLUE;
      }
    }

    &--campaign {
      [data-pagename="campaign"] & {
        pointer-events: none;
        color: $COLOR_BLUE;
      }
    }

    &--news {
      [data-pagename="news"] & {
        pointer-events: none;
        color: $COLOR_BLUE;
      }
    }

    &--howToPlay {
      [data-pagename="how_to_play"] & {
        pointer-events: none;
        color: $COLOR_BLUE;
      }
    }

    &--story {
      [data-pagename="story"] & {
        pointer-events: none;
        color: $COLOR_BLUE;
      }
    }

    &--character {
      [data-pagename="character"] & {
        pointer-events: none;
        color: $COLOR_BLUE;
      }
    }

    .is-pc & {
      transition: color 0.3s, text-shadow 0.3s;

      &:hover {
        color: $COLOR_BLUE;
        text-shadow: 0 0 40px #007fff;
      }
    }
  }

  &__sub {
    width: rsize(62);
    height: rsize(268);
    position: absolute;
    right: 0;
    // bottom: rsize(190);
    top: 30vh;
    z-index: 1;
    background-image: url(#{$IMAGE_DIR}bg_sub_menu.png);
    background-repeat: no-repeat;
    background-size: contain;

    @include lg {
      width: 100px;
      height: 428px;
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
    }
  }

  &__subList {
    position: absolute;
    top: 50%;
    left: rsize(25);
    z-index: 1;
    transform: translateY(-50%);

    @include lg {
      right: 20px;
      left: auto;
    }
  }

  &__subItem {
    &:nth-child(n + 2) {
      margin-top: rsize(40);

      @include lg {
        margin-top: 50px;
      }
    }
  }

  &__subLink {
    .is-pc & {
      #{$subIcon} {
        transition: transform 0.3s;
      }

      &:hover {
        #{$subIcon} {
          transform: scale(1.4);
        }
      }
    }
  }

  &__subIcon {
    width: rsize(25);
    height: rsize(22);
    fill: $COLOR_WHITE;
    filter: drop-shadow(0 0 rsize(10) #128ffc);

    @include lg {
      width: 35px;
      height: 28px;
    }
  }
}
