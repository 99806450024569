.c-BackButton {
  // width: rsize(150);
  position: fixed;
  left: rsize(10);
  bottom: rsize(60);
  z-index: 2;

  @include lg {
    display: table;
    position: static;
    // width: 150px;
  }

  .c-Button {
    padding: rsize(4) rsize(25) rsize(4) rsize(35);

    @include lg {
      font-size: 1.4rem;
      padding: 6px 25px 6px 35px;
    }
  }

  &.is-abs {
    position: absolute;
    bottom: rsize(-70);
  }
}
