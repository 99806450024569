.c-Header {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  pointer-events: none;

  &__logo {
    display: none;

    [data-pagename="top"] & {
      display: none;
    }

    @include lg {
      display: block;
      width: 198px;
      position: absolute;
      top: 0;
      left: 20px;
      z-index: 1;
      pointer-events: all;
    }
  }

  &__inner {
    width: 100%;
    height: rsize(50);
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 2;
    background-color: $COLOR_WHITE;
    pointer-events: all;

    @include lg {
      width: 306px;
      height: 90px;
      top: 0;
      right: 0;
      bottom: auto;
      left: auto;
      background-color: transparent;
    }

    &::before {
      content: "";
      width: rsize(36);
      position: absolute;
      top: 0;
      left: 50%;
      z-index: -1;
      transform: translateX(-50%);
      border-top: rsize(6) solid $COLOR_BLUE;
      border-right: rsize(6) solid transparent;
      border-left: rsize(6) solid transparent;

      @include lg {
        content: none;
      }
    }

    &::after {
      content: "";
      width: 100%;
      height: rsize(2);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background-color: $COLOR_BLUE;

      @include lg {
        content: none;
      }
    }
  }

  &__download {
    width: rsize(169);
    height: rsize(70);
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-image: url(#{$IMAGE_DIR}bg_download_sm.png);
    background-repeat: no-repeat;
    background-size: contain;

    @include lg {
      display: flex;
      justify-content: space-between;
      width: 280px;
      height: 90px;
      top: 0;
      right: 76px;
      bottom: auto;
      padding-right: 10px;
      padding-left: 60px;
      background-image: url(#{$IMAGE_DIR}bg_download_lg.png);
      background-size: 280px 90px;
      background-position: right top;
    }
  }

  &__downloadLink {
    display: none;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: rsize(10);
    padding-left: rsize(30);

    @include lg {
      padding-top: 0;
      padding-bottom: 14px;
      padding-left: 0;
    }

    &--google {
      .is-android &,
      .is-pc & {
        display: flex;
      }
    }

    &--apple {
      .is-ios &,
      .is-pc & {
        display: flex;
      }
    }
  }

  &__downloadImg {
    width: rsize(110);

    @include lg {
      // width: 100px;
      width: auto;
      height: 33px;
    }
  }
}
