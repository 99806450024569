// -------------------------------------------------------------------
//  mediaquery.scss
// -------------------------------------------------------------------

@mixin sm() {
  @media (min-width: 1px) {
    @content;
  }
}

@mixin md() {
  @media (min-width: $BREAK_POINT_MD) {
    @content;
  }
}

@mixin lg() {
  @media (min-width: $BREAK_POINT_LG) {
    @content;
  }
}

@mixin xl() {
  @media (min-width: $BREAK_POINT_XL) {
    @content;
  }
}

@mixin sm-only() {
  @media (min-width: 1px) and (max-width: $BREAK_POINT_MD - 0.02px) {
    @content;
  }
}

@mixin md-only() {
  @media (min-width: $BREAK_POINT_MD) and (max-width: $BREAK_POINT_LG - 0.02px) {
    @content;
  }
}

@mixin sm-md() {
  @media (min-width: 1px) and (max-width: $BREAK_POINT_LG - 0.02px) {
    @content;
  }
}

@mixin landscape() {
  @media (orientation: landscape) {
    @content;
  }
}
