.p-Terms {
  padding: 0 rsize(20);
  margin-top: rsize(30);

  @include lg {
    max-width: 940px;
    padding: 0;
    margin-top: 70px;
    margin-right: auto;
    margin-left: auto;
  }

  &__back {
    @include lg {
      margin-top: 40px;
    }
  }
}