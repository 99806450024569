.c-Template {
  padding-top: rsize(37);

  @include lg {
    padding-top: 40px;
    padding-bottom: 80px;
  }

  iframe {
    width: 100%;
    height: 50.2vw;

    @include lg {
      height: 394px;
    }
  }

  h2 {
    font-size: rsize(18);
    font-weight: 700;
    margin-bottom: rsize(40);

    @include lg {
      font-size: 2rem;
      margin-bottom: 40px;
    }
  }

  h3 {
    font-size: rsize(14);
    font-weight: 700;
    margin-bottom: rsize(20);

    @include lg {
      font-size: 1.6rem;
      margin-bottom: 20px;
    }
  }

  p {
    font-size: rsize(14);
    margin-bottom: rsize(30);

    @include lg {
      font-size: 1.4rem;
      margin-bottom: 40px;
    }
  }

  figcaption {
    font-size: rsize(12);
    margin-bottom: rsize(20);

    @include lg {
      font-size: 1.2rem;
      margin-top: 5px;
      margin-bottom: 40px;
    }
  }

  img {
    margin-right: auto;
    margin-left: auto;
  }

  hr {
    margin-top: rsize(30);
    margin-bottom: rsize(30);

    @include lg {
      border-top-width: 2px;
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  a {
    font-size: rsize(14);
    color: $COLOR_BLUE;
    text-decoration: underline;

    @include lg {
      font-size: 1.4rem;
    }

    &::after {
      content: "";
      display: inline-block;
      width: rsize(5);
      height: rsize(10);
      background-image: url(#{$IMAGE_DIR}arrow_right_blue.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      vertical-align: rsize(0);
      margin-left: rsize(5);

      @include lg {
        width: 7px;
        height: 10px;
        margin-left: 10px;
      }
    }

    &[target="_blank"] {
      &::after {
        width: rsize(10);
        height: rsize(10);
        background-image: url(#{$IMAGE_DIR}new_window.svg);
        vertical-align: rsize(1);

        @include lg {
          width: 11px;
          height: 11px;
          margin-left: 10px;
          vertical-align: 0;
        }
      }
    }
  }
}
