.p-HowToPlay {
  @include lg {
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
  }

  &__videoListContainer {
    padding: 0 rsize(20);

    @include lg {
      padding: 0;
      margin-top: 30px;
    }
  }

  &__videoList {
    margin: rsize(-40) 0;

    @include lg {
      display: flex;
      flex-wrap: wrap;
      margin: -10px;
    }
  }

  &__videoItem {
    margin: rsize(40) 0;

    @include lg {
      width: 320px;
      margin: 10px;
    }
  }
}
