.c-Button {
  display: block;
  position: relative;
  z-index: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid;
  border-radius: rsize(15) rsize(6);
  text-align: center;
  padding: 0.5em 1em;
  font-family: $FONT_M_PLUS_1p;
  font-size: rsize(16);
  font-weight: 800;
  cursor: pointer;

  @include lg {
    font-size: 1.8rem;
    border-radius: 18px 8px;
  }

  &--blue {
    color: #4090f7;
    border-color: #4090f7;
    box-shadow: 0 0 rsize(5) rgba(#128ffc, 0.2);

    @include lg {
      box-shadow: 0 0 20px rgba(#128ffc, 0.2);
    }

    &::before {
      content: "";
      width: rsize(7);
      height: rsize(11);
      position: absolute;
      top: 50%;
      right: rsize(20);
      z-index: 1;
      transform: translateY(-50%);
      background-image: url(#{$IMAGE_DIR}arrow_right_blue.svg);
      background-size: contain;
      background-repeat: no-repeat;

      @include lg {
        width: 8px;
        height: 12px;
        right: 20px;
      }
    }

    .is-pc & {
      transition: color 0.3s, background-color 0.3s, border-color 0.3s;

      &::before {
        transition: transform 0.3s;
      }

      &:hover {
        color: $COLOR_WHITE;
        background-color: $COLOR_BLUE;
        border-color: $COLOR_WHITE;

        &::before {
          background-image: url(#{$IMAGE_DIR}arrow_right_wht.svg);
          transform: translate(3px, -50%);
        }
      }
    }
  }

  &--back {
    &::before {
      left: rsize(20);
      right: auto;
      background-image: url(#{$IMAGE_DIR}arrow_left_blue.svg);

      @include lg {
        left: 20px;
      }
    }

    .is-pc & {
      transition: color 0.3s, background-color 0.3s, border-color 0.3s;

      &::before {
        transition: transform 0.3s;
      }

      &:hover {
        color: $COLOR_WHITE;
        background-color: $COLOR_BLUE;
        border-color: $COLOR_WHITE;

        &::before {
          background-image: url(#{$IMAGE_DIR}arrow_left_wht.svg);
          transform: translate(-3px, -50%);
        }
      }
    }
  }
}
